import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import ModalDelete from "../../components/modal/modalHapus";
import ModalImage from "../../components/modal/modalImage";
import ModalTransfer from "../../components/modal/modalTransfer";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Menu,
  ListItemIcon,
  Modal,
  Chip,
  Popover,
  TextField,
  InputBase,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faAdd,
  faCalendarAlt,
  faCaretDown,
  faDollarSign,
  faEllipsis,
  faEye,
  faFilter,
  faHandshakeSimple,
  faImage,
  faMagnifyingGlass,
  faPlusSquare,
  faScaleUnbalanced,
  faTimes,
  faTrash,
  faTrashCan,
  faTruck,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaParachuteBox } from "react-icons/fa";
import Slider from "react-slick";
import ModalPerpanjang from "../../components/modal/modalPerpanjang";
import ModalKonfirmasi from "../../components/modal/modalKonfirmasi";
import ModalLunaskan from "../../components/modal/modalLunaskan";
import ModalSubsidi from "../../components/modal/modalSubsidi";
import dayjs from "dayjs";
import ModalFilterDate from "../../components/modal/modalFilterDate";
import ReportsExcel from "../../components/reports/Reports";

const Subsidi = () => {
  //---------------var filter--------------
  const [statusFilter, setStatusFilter] = useState("n");
  const [kategori, setKategori] = useState("n");
  const [filterMenu, setFilterMenu] = useState(null);
  const filter = Boolean(filterMenu);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const date = Boolean(filterDate);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("ID Barang");
  const [searchVal, setSearchVal] = useState("id_barang");
  const token = localStorage.getItem("Mitra-Token");
  const status_akun = localStorage.getItem("status_akun");
  const navigate = useNavigate();

  const [urlPrint, setUrlPrint] = useState("");

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/subsidi?page=${page}&per_page=${rowsPerPage}`;
      if (statusFilter !== "n") {
        url = url + "&status=" + statusFilter;
      }
      if (kategori !== "n") {
        url = url + "&kategori=" + kategori;
      }
      if (search !== "" && clearSearch === "N") {
        url = url + "&" + searchVal + "=" + search;
      }
      if (startDate) {
        const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (endDate) {
        const formattedDate = dayjs(endDate).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
      setUrlPrint(url + "&print=Y");

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const changeStart = (start) => {
    setStartDate(start);
  };
  const changeEnd = (end) => {
    setEndDate(end);
  };
  const removeFilter = () => {
    setStatusFilter("n");
    setKategori("n");
  };
  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilterDate = async () => {
    setFilterDate(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilter = async () => {
    setFilterMenu(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "id_barang", text: "ID Barang" },
    { val: "name", text: "Nama Barang" },
    { val: "no_seri", text: "Nomor Seri" },
  ];

  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);
  const [status, setStatus] = useState("");
  const [status_subsidi, setStatusSubsidi] = useState("");
  const [cour_subsidi, setCourSubsidi] = useState("");
  const [other_subsidi, setOtherSubsidi] = useState("");
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick =
    (id, status, status_subsidi, cour_subsidi, other_subsidi) => (event) => {
      setId(id);
      setStatusSubsidi(status_subsidi);
      setOtherSubsidi(other_subsidi);
      setCourSubsidi(cour_subsidi);
      setAnchorEl(event.currentTarget);
    };
  const handleApprove = async () => {
    try {
      const response = await api.post(`v1/dashboard/subsidi/approve`, {
        headers: { "Mitra-Token": token },
      });

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleLunas = async (cour, other) => {
    try {
      let paramSubsidi = '';
      if(cour !== null){
          paramSubsidi+= `&courier_subsidi=${cour}`;
      }
      if(other !== null){
        paramSubsidi+= `&other_subsidi=${other}`;
      } 
      const response = await api.put(
        `v1/dashboard/subsidi/approve?id=${id}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      dataIndex: "name",
      ellipsis: true,
      key: "nama",
      render: (
        _,
        {
          id_barang,
          status_subsidi,
          id,
          other_subsidi,
          courier_subsidi,
          status,
          name,
          no_seri,
          category,
          sell_price,
        }
      ) => (
        <>
          <Stack
            direction="row"
            spacing={1}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick(
              id,
              status_subsidi,
              courier_subsidi,
              other_subsidi
            )}
          >
            <Stack style={{ width: "80px" }}>
              <center>
                <Typography
                  variant="body"
                  style={{ margin: "auto 0", marginRight: "5px" }}
                >
                  #{id_barang}
                </Typography>
              </center>
              <center>
                {status_subsidi === 1 && (
                  <Chip
                    style={{ margin: "0 auto" }}
                    size="small"
                    color="primary"
                    label="Diberikan"
                  />
                )}

                {status_subsidi === 0 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    color="warning"
                    size="small"
                    label="Proses"
                  />
                )}
              </center>
            </Stack>
            <Stack>
              <Typography variant="body2">
                {category} - {name}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.4)" }}>
                {no_seri}
              </Typography>
            </Stack>
          </Stack>
        </>
      ),
    },
    {
      dataIndex: "id",
      render: (_, { type, created_date,description }) => (
        <>
          <Stack style={{ marginLeft: "15px" }}>
            <Typography variant="body2">
              {description}
            </Typography>
            <Typography variant="caption">{created_date}</Typography>
          </Stack>
        </>
      ),
    },
  ];
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Popover
        id="filter-menu"
        open={filter}
        anchorEl={filterMenu}
        onClose={(e) => {
          setFilterMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "250px" }}>
          <Typography variant="body" sx={{ fontSize: "14px" }}>
            Filter
          </Typography>
          <TextField
            select
            label="Kategori"
            defaultValue="n"
            fullWidth
            size="small"
            value={kategori}
            onChange={(e) => {
              setKategori(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="HP" value="HP">
              HP
            </MenuItem>
            <MenuItem key="TV" value="TV">
              TV
            </MenuItem>
            <MenuItem key="LAPTOP" value="LAPTOP">
              LAPTOP
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Status"
            defaultValue="n"
            fullWidth
            size="small"
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Proses" value="0">
              Proses
            </MenuItem>
            <MenuItem key="Diberikan" value="1">
              Diberikan
            </MenuItem>
          </TextField>
          <Button
            fullwidth
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              removeFilter();
            }}
          >
            Hilangkan
          </Button>
          <Button
            fullwidth
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              addFilter();
            }}
          >
            Terapkan
          </Button>
        </div>
      </Popover>
      <ModalFilterDate
        open={date}
        anchorEl={filterDate}
        onClose={(e) => {
          setFilterDate(null);
        }}
        onApplyFilter={addFilterDate}
        start={startDate}
        end={endDate}
        changeStart={changeStart}
        changeEnd={changeEnd}
      ></ModalFilterDate>
      <ModalSubsidi
        isOpen={modalKonfirmasi}
        onClose={() => setModalKonfirmasi(false)}
        onChange={handleLunas}
        cour={cour_subsidi}
        other={other_subsidi}
      ></ModalSubsidi>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={goTo("/dashboard/subsidi/detail/" + id)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
          </ListItemIcon>
          <Typography variant="inherit">Detail</Typography>
        </MenuItem>
        {status_akun === "OWNER" && status_subsidi === 0 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              setModalKonfirmasi(true);
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faHandshakeSimple}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant>Berikan Subsidi</Typography>
          </MenuItem>
        )}
      </Menu>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Data Subsidi
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterMenu(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
            </IconButton>
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterDate(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
            </IconButton>

            <ReportsExcel
              laporan={
                (startDate &&
                  endDate &&
                  "Data Subsidi " + startDate + " - " + endDate) ||
                "Data Subsidi (All)" + new Date().toLocaleDateString()
              }
              url={urlPrint}
            ></ReportsExcel>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e)=> {
                          if(e.key === 'Enter'){
                            handleSearch();
                          }
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                scroll={{
                  x: 500,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                size="small"
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Subsidi;
