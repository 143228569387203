import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  Popover,
  TextField,
  MenuItem,
  Menu,
  InputBase,
} from "@mui/material";
import Slider from "react-slick";
import TableComponent from "../../components/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { Pagination, Table, Spin } from "antd";
import {
  faAdd,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowUp,
  faCalendarAlt,
  faCaretDown,
  faEllipsis,
  faFilter,
  faMagnifyingGlass,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import ReportsExcel from "../../components/reports/Reports";
import ModalFilterDate from "../../components/modal/modalFilterDate";

const BarangMasuk = () => {
  //---------------var filter--------------
  const [keterangan, setKeterangan] = useState("n");
  const [kategori, setKategori] = useState("n");
  const [filterMenu, setFilterMenu] = useState(null);
  const filter = Boolean(filterMenu);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const date = Boolean(filterDate);

  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("ID Barang");
  const [urlPrint, setUrlPrint] = useState("");
  const [searchVal, setSearchVal] = useState("id");
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/barang/transaksi?page=${page}&per_page=${rowsPerPage}`;
      if (kategori !== "n") {
        url = url + "&kategori=" + kategori;
      }
      if (keterangan !== "n") {
        url = url + "&status=" + keterangan;
      }
      if (search !== "" && clearSearch === "N") {
        url = url + "&" + searchVal + "=" + search;
      }
      if (startDate) {
        const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (endDate) {
        const formattedDate = dayjs(endDate).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }

      setUrlPrint(url + "&print=Y");

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilter = async () => {
    setFilterMenu(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const changeStart = (start) => {
    setStartDate(start);
  };
  const changeEnd = (end) => {
    setEndDate(end);
  };
  const removeFilter = () => {
    setKategori("n");
    setKeterangan("n");
  };
  const addFilterDate = async () => {
    setFilterDate(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "keterangan", text: "Keterangan" },
    { val: "id_barang", text: "ID Barang" },
    { val: "name", text: "Nama Barang" },
    { val: "no_seri", text: "Nomor Seri" },
  ];
  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const [imageList, setImageList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);
  const open = Boolean(anchorEl);
  const handleClick = (id) => (event) => {
    setId(id);
    setAnchorEl(event.currentTarget);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    {
      title: "Info Barang",
      dataIndex: "id_barang",
      key: "id_barang",
      ellipsis: true,
      width: "35%",
      render: (
        _,
        {
          id_barang,
          no_seri,
          name,
          category,
          sell_price,
          final_price,
          type,
          total,
        }
      ) => (
        <>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="body2"
              style={{
                fontWeight: "bold",
              }}
            >
              #{id_barang}
            </Typography>
            <Typography variant="body2">
              {category} - {name}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.5)" }}>
              {no_seri}
            </Typography>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)" }}>
              Rp.
              {total
                ? total
                    .toLocaleString("id-ID", 0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                : total}
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      title: "Keterangan",
      dataIndex: "status",
      key: "status",
      render: (_, { type, description }) => (
        <>
          <Typography variant="caption">
            <FontAwesomeIcon
              icon={
                (type === "IN" && faArrowDown) || (type === "OUT" && faArrowUp)
              }
              style={{ marginRight: "2px" }}
            ></FontAwesomeIcon>
            {description}
          </Typography>
        </>
      ),
    },
    {
      title: "Tanggal dibuat",
      dataIndex: "created_date",
      key: "created_date",
      ellipsis: true,
    },
    {
      title: "Aksi",
      dataIndex: "id",
      key: "aksi",
      width: "70px",
      ellipsis: true,

      render: (_, { id }) => (
        <div>
          <IconButton
            sx={{ right: 0, fontSize: "20px" }}
            variant="text"
            size="small"
            fullwidth
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick(id)}
          >
            <FontAwesomeIcon icon={faEllipsis} color="red"></FontAwesomeIcon>
          </IconButton>
        </div>
      ),
    },
  ];
  // const tokenString = localStorage.getItem("Mitra-Token");
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Popover
        id="filter-menu"
        open={filter}
        anchorEl={filterMenu}
        onClose={(e) => {
          setFilterMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "250px" }}>
          <Typography variant="body" sx={{ fontSize: "14px" }}>
            Filter
          </Typography>
          <TextField
            select
            label="Kategori"
            defaultValue="n"
            fullWidth
            size="small"
            value={kategori}
            onChange={(e) => {
              setKategori(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="HP" value="HP">
              HP
            </MenuItem>
            <MenuItem key="TV" value="TV">
              TV
            </MenuItem>
            <MenuItem key="LAPTOP" value="LAPTOP">
              LAPTOP
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Keterangan"
            defaultValue="n"
            fullWidth
            size="small"
            value={keterangan}
            onChange={(e) => {
              setKeterangan(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Baru" value="IN">
              Pembelian
            </MenuItem>
            <MenuItem key="Bekas" value="OUT">
              Penjualan
            </MenuItem>
          </TextField>
          <Button
            fullwidth
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              removeFilter();
            }}
          >
            Hilangkan
          </Button>
          <Button
            fullwidth
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              addFilter();
            }}
          >
            Terapkan
          </Button>
        </div>
      </Popover>
      <ModalFilterDate
        open={date}
        anchorEl={filterDate}
        onClose={(e) => {
          setFilterDate(null);
        }}
        onApplyFilter={addFilterDate}
        start={startDate}
        end={endDate}
        changeStart={changeStart}
        changeEnd={changeEnd}
      ></ModalFilterDate>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Transaksi
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterMenu(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
            </IconButton>
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterDate(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
            </IconButton>
            <ReportsExcel
              laporan={
                (startDate &&
                  endDate &&
                  "Laporan Data Transaksi " + startDate + " - " + endDate) ||
                "Laporan Data Transkasi (All)" + new Date().toLocaleDateString()
              }
              url={urlPrint}
            ></ReportsExcel>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e)=> {
                          if(e.key === 'Enter'){
                            handleSearch();
                          }
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                scroll={{
                  x: 600,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BarangMasuk;
