import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Home.css";
import "../css/Base.css";
import api from "./Api.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "../components/product";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownWideShort,
  faCircleExclamation,
  faExclamation,
  faFilter,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import {
  FilterMarketPlace,
  SortMarketPlace,
} from "../components/modal/modalAll.js";
import { param } from "jquery";
import Skeleton from "react-loading-skeleton";
import { FaStackOverflow } from "react-icons/fa";

const BarangNonUser = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [skeleton , setSkeleton] = useState([{
    barang :true
  }])
  const [params, setParams] = useSearchParams();

  const [modal, setModal] = useState({
    marketPlace: false,
    sort: false,
    anchorMP: null,
    anchorSort: null,
  });
  const [filter, setFilter] = useState({
    kategori: "n",
    toko: "n",
    sort: "n",
  });

  const [kategori, setKategori] = useState(params.get("kategori") || "n");
  const [toko, setToko] = useState(params.get("toko") || "n");
  const [sort, setSort] = useState(params.get("sort") || "n");
  const [search, setSearch] = useState(params.get("search") || "n");

  useEffect(() => {
    setKategori(params.get("kategori") || "n");
    setToko(params.get("toko") || "n");
    setSort(params.get("sort") || "n");
    setSearch(params.get("search") || "n");
  }, [params]);

  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const fetchMoreData = async (fromPage = page, fromData = data) => {
    if (isLoading) return;
    setIsLoading(true);
    setSkeleton({banner:true});

    setTimeout(async () => {
      try {
        let searchs = "";
        if (params.get("search")) {
          searchs = "search=" + params.get("search") + "&";
        }
        let query = `${searchs}kategori=${kategori}&toko=${toko}&sort=${sort}`;
        let url = `barang_nu?page=${fromPage}&perPage=5&${query}`;

        const response = await api.get(url);
        const newData = response.data.data.data;

        if (newData.length < 6) {
          setHasMore(false);
          setIsLoading(false);
          setSkeleton({banner:false});

        }
        setData([...fromData, ...newData]);
        setPage(fromPage + 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
        setSkeleton({banner:false});

      }
    }, 100);
  };

  const addFilterMarketplace = (data, set) => {
    setModal({
      anchorMP: null,
      anchorSort: null,
    });
    let searchs = "";
    if (params.get("search")) {
      searchs = "search=" + params.get("search");
    }
    if (set === "Y") {
      searchs += "&";
      const newKategori = data.kategori || "n";
      const newToko = data.toko || "n";
      const newSort = data.sort || "n";

      setKategori(newKategori);
      setToko(newToko);
      setSort(newSort);

      navigate(
        `/barang?${searchs}kategori=${newKategori}&toko=${newToko}&sort=${newSort}`
      );
    } else {
      setKategori("n");
      setToko("n");
      setSort("n");
      navigate("/barang?" + searchs);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setHasMore(true);
        await fetchMoreData(1, []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [params]);

  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <FilterMarketPlace
        open={Boolean(modal.anchorMP)}
        anchorEl={modal.anchorMP}
        onClose={(e) => {
          setModal({ ...modal, anchorMP: null });
        }}
        onApplyFilter={addFilterMarketplace}
      ></FilterMarketPlace>
      <SortMarketPlace
        open={Boolean(modal.anchorSort)}
        anchorEl={modal.anchorSort}
        onClose={(e) => {
          setModal({ ...modal, anchorSort: null });
        }}
        onApplyFilter={addFilterMarketplace}
      ></SortMarketPlace>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          <Button
            variant="contained"
            size="small"
            sx={{ mr: "10px" }}
            onClick={(e) => {
              setModal({ ...modal, anchorMP: e.currentTarget });
            }}
          >
            <Typography variant="caption">
              <FontAwesomeIcon icon={faFilter} fontSize={11} /> Filter
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={(e) => {
              setModal({ ...modal, anchorSort: e.currentTarget });
            }}
          >
            <Typography variant="caption">
              <FontAwesomeIcon icon={faArrowDownWideShort} fontSize={11} />{" "}
              Urutkan
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={hasMore}
      >
        <Grid container spacing={0.9} sx={{ paddingTop: 2.5, paddingBottom: 2.5 }}>

          {data.map((item, index) => (
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <ProductCard
                mitra={item.mitra}
                name={item.name}
                price={item.price}
                views={item.views}
                url={item.url}
                id={item.id}
              ></ProductCard>
            </Grid>
          ))}
          {skeleton.banner ? (
             Array(4) 
             .fill()
             .map(() => (
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div style={{borderRadius:"20px", overflow:"hidden"}}>
                <Skeleton height={240} sx={{border:"100px"}}></Skeleton>
              </div>
            </Grid>
          ))
          ) : (
              ""
          )}

          {hasMore ? (
            ""
          ) : (
            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              sx={{ m: "0 auto", height: "100px" }}
            >
              <center>
                <Typography variant="caption">
                  Tidak ada lagi data untuk ditampilkan
                </Typography>
              </center>
            </Grid>
          )}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

export default BarangNonUser;
