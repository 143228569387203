import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import ModalDelete from "../../components/modal/modalHapus";
import ModalImage from "../../components/modal/modalImage";
import ModalTransfer from "../../components/modal/modalTransfer";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
  Stack,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Menu,
  ListItemIcon,
  Modal,
  Chip,
  Popover,
  TextField,
  InputBase,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faAdd,
  faCalendarAlt,
  faCaretDown,
  faDollarSign,
  faEllipsis,
  faEye,
  faFilter,
  faImage,
  faMagnifyingGlass,
  faPlusSquare,
  faScaleUnbalanced,
  faTimes,
  faTrash,
  faTrashCan,
  faTruck,
  faTruckArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaParachuteBox } from "react-icons/fa";
import Slider from "react-slick";
import ModalPerpanjang from "../../components/modal/modalPerpanjang";
import ModalKonfirmasi from "../../components/modal/modalKonfirmasi";
import ModalLunaskan from "../../components/modal/modalLunaskan";
import dayjs from "dayjs";
import ModalFilterDate from "../../components/modal/modalFilterDate";
import ReportsExcel from "../../components/reports/Reports";

const Gadai = () => {
  //---------------var filter--------------
  const [statusFilter, setStatusFilter] = useState("n");
  const [kategori, setKategori] = useState("n");
  const [filterMenu, setFilterMenu] = useState(null);
  const filter = Boolean(filterMenu);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const date = Boolean(filterDate);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("ID Barang");
  const [searchVal, setSearchVal] = useState("id_barang");
  const token = localStorage.getItem("Mitra-Token");
  const navigate = useNavigate();

  const [urlPrint, setUrlPrint] = useState("");

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/gadai?page=${page}&per_page=${rowsPerPage}`;
      if (statusFilter !== "n") {
        url = url + "&status=" + statusFilter;
      }
      if (kategori !== "n") {
        url = url + "&kategori=" + kategori;
      }
      if (clearSearch == "N") {
        if (search !== "") {
          url = url + "&" + searchVal + "=" + search;
        }
      }
      if (startDate) {
        const formattedDate = dayjs(startDate).format("YYYY-MM-DD");
        url = url + "&start=" + formattedDate;
      }
      if (endDate) {
        const formattedDate = dayjs(endDate).format("YYYY-MM-DD");
        url = url + "&end=" + formattedDate;
      }
      setUrlPrint(url + "&print=Y");

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };
  const changeStart = (start) => {
    setStartDate(start);
  };
  const changeEnd = (end) => {
    setEndDate(end);
  };
  const removeFilter = () => {
    setStatusFilter("n");
    setKategori("n");
  };
  const addFilterDate = async () => {
    setFilterDate(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const addFilter = async () => {
    setFilterMenu(null);
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "id", text: "ID" },
    { val: "id_barang", text: "ID Barang" },
    { val: "name", text: "Nama Barang" },
    { val: "no_seri", text: "No Seri" },
  ];
  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [total_amount, setTotalAmount] = useState(0);
  const [modalPerpanjang, setModalPerpanjang] = useState(false);
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);
  const [modalLelangkan, setModalLelangkan] = useState(false);
  const [days, setDays] = useState(0);
  const [exc_days, setExcDays] = useState(0);
  const [hari_ke, setHariKe] = useState(0);
  const [status, setStatus] = useState("");
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick =
    (id, total_amount, hari_ke, days, exc_days, status) => (event) => {
      setId(id);
      setDays(days);
      setExcDays(exc_days);
      setHariKe(hari_ke);
      setTotalAmount(total_amount);
      setAnchorEl(event.currentTarget);
      setStatus(status);
    };
  const handleHapus = async () => {
    try {
      const response = await api.delete(`v1/dashboard/barang?id=${id}`, {
        headers: { "Mitra-Token": token },
      });

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleLelang = async () => {
    try {
      const response = await api.put(
        `v1/dashboard/gadai/lelangkan?id=${id}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleLunas = async (fee) => {
    try {
      const response = await api.put(
        `v1/dashboard/gadai/lunaskan?id=${id}&total=${fee}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handlePerpanjang = async (fee) => {
    try {
      const response = await api.put(
        `v1/dashboard/gadai/perpanjang?id=${id}&total_amount=${fee}`,
        null,
        {
          headers: { "Mitra-Token": token },
        }
      );
      if (response) {
        const resData = response.data;
        if (resData.status == "success") {
          toast.success(resData.message);
          const responseData = await fetchData(page, rowsPerPage);
          setData(responseData.data);
          setRowsPerPage(responseData.last_page);
          setTotal(responseData.total);
          setPageSize(responseData.per_page);
        } else {
          toast.warning(resData.message);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      dataIndex: "name",
      ellipsis: true,
      key: "nama",
      render: (
        _,
        {
          name,
          no_seri,
          category,
          id_barang,
          sell_price,
          status,
          barang_name,
          total_amount,
          total,
          hari_ke,
          days,
          exc_days,
          id,
        }
      ) => (
        <>
          <Stack
            direction="row"
            spacing={1}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick(
              id,
              total_amount,
              hari_ke,
              days,
              exc_days,
              status
            )}
          >
            <Stack style={{ width: "70px" }}>
              <center>
                <Typography
                  variant="body"
                  style={{ margin: "auto 0", marginRight: "5px" }}
                >
                  #{id_barang}
                </Typography>
              </center>
              <center>
                {status === 1 && (
                  <Chip
                    style={{ margin: "0 auto" }}
                    size="small"
                    color="warning"
                    label="Berjalan"
                  />
                )}
                {status === 2 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    size="small"
                    color="primary"
                    label="Lunas"
                  />
                )}
                {status === 0 && (
                  <Chip
                    style={{ margin: "auto auto" }}
                    color="error"
                    size="small"
                    label="Tunda"
                  />
                )}
              </center>
            </Stack>

            <Stack>
              <Typography variant="body2">
                {category} - {barang_name}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.4)" }}>
                {no_seri}
              </Typography>
              {status > 1 && (
                <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)" }}>
                  Rp.{" "}
                  {total_amount
                    ? total_amount
                        .toLocaleString("id-ID", 0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : total_amount}
                </Typography>
              )}
            </Stack>
          </Stack>
        </>
      ),
    },
  ];
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Popover
        id="filter-menu"
        open={filter}
        anchorEl={filterMenu}
        onClose={(e) => {
          setFilterMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px", width: "250px" }}>
          <Typography variant="body" sx={{ fontSize: "14px" }}>
            Filter
          </Typography>
          <TextField
            select
            label="Kategori"
            defaultValue="n"
            fullWidth
            size="small"
            value={kategori}
            onChange={(e) => {
              setKategori(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="HP" value="HP">
              HP
            </MenuItem>
            <MenuItem key="TV" value="TV">
              TV
            </MenuItem>
            <MenuItem key="LAPTOP" value="LAPTOP">
              LAPTOP
            </MenuItem>
          </TextField>
          <TextField
            select
            label="Status"
            defaultValue="n"
            fullWidth
            size="small"
            value={statusFilter}
            onChange={(e) => {
              setStatusFilter(e.target.value);
            }}
            sx={{ mt: 2 }}
          >
            <MenuItem key="Tidak ada" value="n">
              Tidak ada
            </MenuItem>
            <MenuItem key="Berjalan" value="1">
              Berjalan
            </MenuItem>
            <MenuItem key="Lunas" value="2">
              Lunas
            </MenuItem>
            <MenuItem key="Tunda" value="0">
              Tunda
            </MenuItem>
          </TextField>
          <Button
            fullwidth
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              removeFilter();
            }}
          >
            Hilangkan
          </Button>
          <Button
            fullwidth
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => {
              addFilter();
            }}
          >
            Terapkan
          </Button>
        </div>
      </Popover>
      <ModalFilterDate
        open={date}
        anchorEl={filterDate}
        onClose={(e) => {
          setFilterDate(null);
        }}
        onApplyFilter={addFilterDate}
        start={startDate}
        end={endDate}
        changeStart={changeStart}
        changeEnd={changeEnd}
      ></ModalFilterDate>
      <ModalLunaskan
        isOpen={modalKonfirmasi}
        onClose={() => setModalKonfirmasi(false)}
        onChange={handleLunas}
      ></ModalLunaskan>
      <ModalKonfirmasi
        isOpen={modalLelangkan}
        onClose={() => setModalLelangkan(false)}
        onChange={handleLelang}
        text="Yakin lelangkan barang?"
      ></ModalKonfirmasi>
      <ModalPerpanjang
        total_amount={total_amount}
        isOpen={modalPerpanjang}
        onClose={() => setModalPerpanjang(false)}
        onChange={handlePerpanjang}
      ></ModalPerpanjang>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={goTo("/dashboard/gadai/detail/" + id)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
          </ListItemIcon>
          <Typography variant="inherit">Detail</Typography>
        </MenuItem>

        {status === 1 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              setModalKonfirmasi(true);
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant>Lunas</Typography>
          </MenuItem>
        )}
        {hari_ke >= days && status === 1 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              setModalPerpanjang(true);
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant="inherit">Perpanjang</Typography>
          </MenuItem>
        )}
        {hari_ke >= exc_days && status === 1 && (
          <MenuItem
            onClick={(e) => {
              handleClose();
              setModalLelangkan(true);
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faScaleUnbalanced}></FontAwesomeIcon>
            </ListItemIcon>
            <Typography variant>Lelang</Typography>
          </MenuItem>
        )}
      </Menu>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Data Gadai
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterMenu(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
            </IconButton>
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setFilterDate(e.currentTarget);
              }}
            >
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
            </IconButton>
            <ReportsExcel
              laporan={
                (startDate &&
                  endDate &&
                  " Laporan Data Gadai -  " + startDate + " - " + endDate) ||
                "Laporan Data Gadai (All)" + new Date().toLocaleDateString()
              }
              url={urlPrint}
            ></ReportsExcel>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e)=> {
                          if(e.key === 'Enter'){
                            handleSearch();
                          }
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                size="small"
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Gadai;
