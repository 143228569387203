import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../ApiDashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Stack,
  MenuItem,
  Menu,
  ListItemIcon,
  Chip,
  Popover,
  TextField,
  InputBase,
  Avatar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Table, Spin } from "antd";
import {
  faCalendarAlt,
  faCaretDown,
  faCheck,
  faEye,
  faFilter,
  faMagnifyingGlass,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import ModalKonfirmasi from "../../components/modal/modalKonfirmasi";
import dayjs from "dayjs";
import ModalFilterDate from "../../components/modal/modalFilterDate";
import ReportsExcel from "../../components/reports/Reports";
import ModalInvitation from "../../components/modal/modalInvitation";

const Settings = () => {
  //---------------var filter--------------
  const [statusFilter, setStatusFilter] = useState("n");
  const [kategori, setKategori] = useState("n");
  const [filterMenu, setFilterMenu] = useState(null);
  const filter = Boolean(filterMenu);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const date = Boolean(filterDate);
  //------------var search--------------
  const [searchMenuStat, setSearchMenuStat] = useState(null);
  const searchMenu = Boolean(searchMenuStat);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("Nomor HP");
  const [searchVal, setSearchVal] = useState("no_hp");
  const token = localStorage.getItem("Mitra-Token");
  const status_akun = localStorage.getItem("status_akun");
  const navigate = useNavigate();

  const [urlPrint, setUrlPrint] = useState("");

  const fetchData = async (page, rowsPerPage, clearSearch = "N") => {
    try {
      let url = `v1/dashboard/members?page=${page}&per_page=${rowsPerPage}`;
      if (search !== "" && clearSearch === "N") {
        url = url + "&" + searchVal + "=" + search;
      }

      const response = await api.get(url, {
        headers: { "Mitra-Token": token },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  const goTo = (path) => () => {
    navigate(path);
  };

  const clearSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage, "Y");
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };

  const handleSearch = async () => {
    const responseData = await fetchData(page, rowsPerPage);
    setData(responseData.data);
    setRowsPerPage(responseData.last_page);
    setTotal(responseData.total);
    setPageSize(responseData.per_page);
  };
  const searchBy = [
    { val: "no_hp", text: "Nomor HP" },
    { val: "name", text: "Nama Member" },
  ];

  const handleCloseSearch = () => {
    setSearchMenuStat(null);
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);
  const [status, setStatus] = useState("");
  useEffect(() => {
    const fetchDataAndStopLoading = async () => {
      try {
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      }
    };

    fetchDataAndStopLoading();
  }, [page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (ids, status) => (event) => {
    setId(ids);

    setStatus(status);
    setAnchorEl(event.currentTarget);
  };

  const handleServiceSelesai = async () => {
    try {
      const response = await api.get(
        `v1/dashboard/barang/serviceSelesai?id=${id}`,
        {
          headers: { "Mitra-Token": token },
        }
      );

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
        const responseData = await fetchData(page, rowsPerPage);
        setData(responseData.data);
        setRowsPerPage(responseData.last_page);
        setTotal(responseData.total);
        setPageSize(responseData.per_page);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };
  const handleInvitation = async (data) => {
    try {
      const response = await api.post(
        `v1/dashboard/members/invite`,
        {
          member_id: data.member_id,
          as: data.as,
        },
        {
          headers: { "Mitra-Token": token },
        }
      );

      const resData = response.data;
      if (resData.status == "success") {
        toast.success(resData.message);
      } else {
        toast.warning(resData.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      dataIndex: "name",
      ellipsis: true,
      key: "nama",
      render: (_, { name, id, phone, img_profile, status }) => (
        <>
          <Grid container>
            <Grid item xs={4} md={1} lg={1}>
              <center>
                <Avatar src={img_profile} alt="photoURL" />
                <Typography variant="caption">#{id}</Typography>
              </center>
            </Grid>
            <Grid item xs={8} md={11} lg={11}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {phone}
              </Typography>
              <Chip
                size="small"
                color={status == "ADMIN" ? "primary" : "error"}
                variant="contained"
                label={status}
              />
            </Grid>
          </Grid>
        </>
      ),
    },
  ];
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <ModalInvitation
        isOpen={modalKonfirmasi}
        onClose={() => setModalKonfirmasi(false)}
        onChange={handleInvitation}
      ></ModalInvitation>

      <Grid container spacing={1}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography gutterBottom variant="h6" component="div">
            Daftar members
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ right: 0 }}
              variant="text"
              color="error"
              size="small"
              fullwidth
              onClick={(e) => {
                setModalKonfirmasi(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12} md={8} lg={8}>
                  <Typography gutterBottom variant="body" component="div">
                    Jumlah {total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    style={{
                      borderRadius: "20px",
                      padding: "2px 2px 2px 2px",
                      shadow: "none",
                      border: "none",
                    }}
                  >
                    <Menu
                      dense
                      id="search-menu"
                      open={searchMenu}
                      anchorEl={searchMenuStat}
                      onClose={handleCloseSearch}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {searchBy.map((item) => (
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSearch();
                            setSearchVal(item.val);
                            setSearchText(item.text);
                          }}
                        >
                          <Typography variant="caption">{item.text}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                        ></FontAwesomeIcon>
                      </IconButton>
                      <InputBase
                        fullWidth
                        size="small"
                        style={{ marginTop: "2px", fontSize: "14px" }}
                        placeholder={"Cari berdasarkan " + searchText}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e)=> {
                          if(e.key === 'Enter'){
                            handleSearch();
                          }
                        }}
                      ></InputBase>
                      {search !== "" && (
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setSearch("");
                            clearSearch();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{ fontSize: "15px" }}
                          ></FontAwesomeIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setSearchMenuStat(e.currentTarget);
                        }}
                      >
                        <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                      </IconButton>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={isLoading}
              />
              <Pagination
                style={{ marginTop: "10px" }}
                current={page}
                pageSize={pageSize}
                total={total}
                size="small"
                onChange={handlePageChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
