import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Paper,
  Grid,
  CardMedia,
  Chip,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEye } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import ProductCard from "../product";
import { useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'


const ScrollBarang = ({ product,skeleton }) => {
  console.log(skeleton);
  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };
  return (
    <Grid container style={{ overflowX: "auto",paddingBottom:"15px" }}>
      <Box display="flex" flexDirection="row">
        {skeleton ? (
          Array(3) 
          .fill()
          .map(() => (
          <Box style={{ width: "180px",marginRight:"10px",borderRadius:"20px", overflow:"hidden" }}>
            <Skeleton height={240}></Skeleton>
          </Box>
        ))
        ) : (
          product.map((item, index) => (
            <Box sx={{ width: "180px", marginRight:1 }}>
              <ProductCard
                mitra={item.mitra}
                name={item.name}
                price={item.price}
                views={item.views}
                url={item.url}
                id={item.id}
              ></ProductCard>
            </Box>
          ))
        )}
        <Box
          onClick={goTo("/barang")}
          style={{
            flex: "0 0 auto",
            height: "50%",
            padding: "17% 10px 0 10px",
            marginRight: 7,
            marginLeft: 7,
            maxWidth: 150,
          }}
        >
          <Stack>
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                fontSize: "40px",
                color: "red",
                marginBottom: "10px",
              }}
            ></FontAwesomeIcon>
            <Typography variant="caption" color="red" style={{}}>
              Lihat Lebih Banyak
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Grid>
  );
};
export default ScrollBarang;
