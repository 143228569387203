import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/Home.css";
import "../css/Base.css";
import api from "./Api.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductCard from "../components/scroll-barang";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import logo_3 from "../img/logo-3.png";
import logo_4 from "../img/logo-4.png";
import logo_5 from "../img/logo-5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { AppTasks } from "../sections/@dashboard/app";
import "slick-carousel/slick/slick-theme.css";
import InfiniteScroll from "../components/load-barang";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLaptop,
  faMobile,
  faPhoneSquare,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { TramRounded } from "@mui/icons-material";
import { Stack } from "react-bootstrap";
import { makeStyles } from '@mui/styles';

const Home = () => {
  const token = localStorage.getItem("Mitra-Token");
  const [products, setProducts] = useState([]);
  const [img_carousel, setImgCarousel] = useState([]);
  const [data_loaded, setDataLoaded] = useState(false);
  const [skeleton , setSkeleton] = useState({
      banner: true,
      top_ten:true
    })

  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    // setSkeleton({...skeleton, top_ten: true});
    const fetchDataAndStopLoading = async () => {
      try {
        const response = await api.get("barang_top_ten", null, {});
        const { status, message, data } = response.data;
        if (status === "success") {
          setProducts(response.data.data);
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      }
      finally{
        setSkeleton({top_ten: false});
      }
    };
    fetchDataAndStopLoading();
  }, []);
  const logout = () => {
    return api
      .put("v1/logout", null, {
        headers: { "Mitra-Token": token },
      })
      .then((response) => {
        const { status, message, data } = response.data;

        if (status === "success") {
          toast.success(message);
          navigate("/");
          localStorage.clear();
          setUserData(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          navigate("/");
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    // setSkeleton({...skeleton, banner:true});
    const fetchData = async () => {
    try{
      const response = await api.get("user_carousel", null, {});
      const { status, message, data } = response.data;
      if (status === "success") {
        setImgCarousel(response.data.data);
      }
    }
    catch(error){
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
    finally{
      setSkeleton({banner:false});
    };
  }
  fetchData();
  },[]);

  // const tokenString = localStorage.getItem("Mitra-Token");
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 60000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    accessbility: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {},
      },
    ],
  };
  return (
    <Container
      style={{
        justifyContent: "right",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} lg={12}>
          <div className="carousel-container">
            <Slider {...settings}>
              {skeleton.banner ? (
                <div>
                  <Skeleton height={150}/>
                </div>
              ) : (
                img_carousel.map((data) => (
                  <div onClick={() => {
                    navigate(`${data.redirect_to}`);
                  }}>
                    <img loading="lazy" src={data.img_url} className="carousel-image" />
                  </div>
                )
              )
              )}
            </Slider>
          </div>
        </Grid>
       

        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ paddingLeft: 2,paddingTop:1.2 }}>
            <Typography gutterBottom variant="subtitle2" sx={{fontWeight:"bolder",padding:"5px 0 5px 0"}} color="red" component="div">
              Paling Sering Dilihat
            </Typography>
            <ProductCard product={products} skeleton={skeleton.top_ten}></ProductCard>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ paddingLeft: 2,paddingTop:1 }}>
            <Typography gutterBottom variant="subtitle2" sx={{fontWeight:"bolder",padding:"5px 0 5px 0"}} color="red" component="div">
              Kategori
            </Typography>
            <Grid container style={{ overflowX: "auto",paddingBottom:"10px" }}>
              <Box display="flex" flexDirection="row">
                  <Stack style={{marginRight:"20px"}} onClick={() => {navigate("/barang?kategori=tv");}}>
                    <Card sx={{ height:"75px",width:"75px",boxShadow:"none",border:"1px solid rgb(235,235,235)",borderRadius:"10px" }}>
                      <center>
                      <Typography gutterBottom variant="h6" sx={{ marginTop:"33%"  }}>
                        <FontAwesomeIcon icon={faTv}></FontAwesomeIcon>
                      </Typography>
                      </center>
                    </Card>
                    <center>
                      <Typography gutterBottom variant="caption" component="div">
                        Televisi
                      </Typography>
                    </center>
                  </Stack>
                  <Stack style={{marginRight:"20px"}} onClick={() => {navigate("/barang?kategori=laptop");}}>
                    <Card sx={{ height:"75px",width:"75px",boxShadow:"none",border:"1px solid rgb(235,235,235)",borderRadius:"10px" }}>
                      <center>
                      <Typography gutterBottom variant="h6" sx={{ marginTop:"33%"  }}>
                        <FontAwesomeIcon icon={faLaptop}></FontAwesomeIcon>
                      </Typography>
                      </center>
                    </Card>
                    <center>
                      <Typography gutterBottom variant="caption" component="div">
                        Laptop
                      </Typography>
                    </center>
                  </Stack>
                  <Stack style={{marginRight:"20px"}} onClick={() => {navigate("/barang?kategori=hp");}}>
                    <Card sx={{ height:"75px",width:"75px",boxShadow:"none",border:"1px solid rgb(235,235,235)",borderRadius:"10px" }}>
                      <center>
                      <Typography gutterBottom variant="h6" sx={{ marginTop:"33%"  }}>
                        <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                      </Typography>
                      </center>
                    </Card>
                    <center>
                      <Typography gutterBottom variant="caption" component="div">
                        Handphone
                      </Typography>
                    </center>
                  </Stack>
                  <Stack style={{marginRight:"20px"}} onClick={() => {navigate("/barang");}}>
                    <Card sx={{ height:"75px",width:"75px",boxShadow:"none",border:"1px solid rgb(235,235,235)",borderRadius:"10px" }}>
                      <center>
                      <Typography gutterBottom variant="h6" sx={{ marginTop:"33%" }}>
                        <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                      </Typography>
                      </center>
                    </Card>
                    <center>
                      <Typography gutterBottom variant="caption" component="div">
                        Semuanya
                      </Typography>
                    </center>
                  </Stack>
              </Box>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
